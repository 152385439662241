import { API_URL } from "..";

const API_URL_CATEGORIA = API_URL + "categorias";

export function LIST_CATEGORIES(token) {
  return {
    url: `${API_URL_CATEGORIA}/listar`,
    options: {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}
