import styled from "styled-components";

export const ProductItemsContainer = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;

  gap: 5px;

  > ul {
    width: 100%;
    height: fit-content;

    display: flex;
    flex-direction: column;

    gap: 5px;
    padding: 5px;

    border-width: 1px;
    border-style: solid;
    border-radius: 0.5rem;
    border-color: ${({ theme }) => theme.colors.border_03};

    > li {
      width: 100%;

      display: flex;
      flex-direction: row;

      align-items: center;
      justify-content: space-between;

      text-align: center;

      > button {
        width: fit-content;
      }
    }
  }
`;

export const ProductImagensContainer = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;

  gap: 5px;
`;

export const ProductImagensSubContainer = styled.div`
  width: 100%;
  height: fit-content;

  padding: 10px;

  display: flex;
  flex-direction: column;

  gap: 5px;

  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
  border-color: ${({ theme }) => theme.colors.border_03};

  h3 {
    width: 100%;
    font-weight: 400;
    text-align: center;
  }

  img {
    width: fit-content;
    height: 180px;
    margin: 0 auto;
  }
`;
