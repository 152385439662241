import styled from "styled-components";

export const AuthContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  > form {
    max-width: 500px;
    min-width: 300px;
    max-height: 500px;
    min-height: 300px;

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    gap: 10px;
  }
`;
