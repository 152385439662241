import styled from "styled-components";

export const ProductDetailContainer = styled.div`
  width: 100%;

  height: fit-content !important;

  overflow-y: scroll;

  > div {
    width: 100%;
  }

  > * {
    margin-bottom: 10px;
  }

  > div:nth-child(2) {
    padding: 5px 15px;
    margin: 0px 10px;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;

    gap: 10px;
    align-items: center;

    text-align: center;

    border-radius: 10px;
    border-color: ${({ theme }) => theme.colors.border_03};

    background-color: ${({ theme }) => theme.colors.background_03};
  }

  > div:nth-child(3) {
    height: fit-content;

    > div {
      width: 100%;
      height: fit-content;

      padding: 5px 0px;

      display: flex;
      flex-direction: row;

      gap: 10px;
      align-items: start;
      justify-content: start;

      overflow-x: auto;
      overflow-y: hidden;

      background-color: ${({ theme }) => theme.colors.background_03};

      > div {
        width: fit-content;
        margin: 0;
      }
    }
  }
`;

export const ProductDetailSubContainer = styled.div`
  max-width: 1200px;
  margin: 50px auto;

  display: flex;
  flex-direction: row;

  gap: 10px;
  align-items: center;
  justify-content: center;

  > div:nth-child(1) {
    max-width: 1100px;
    width: 100%;

    height: 400px;
  }

  > div:nth-child(2) {
    max-width: 350px;
    min-width: 300px;

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    align-items: start;
    justify-content: start;

    > div {
      width: 100%;
      padding: 0px 5px;

      display: flex;
      flex-direction: column;

      align-items: start;

      overflow-y: auto;

      > * {
        width: 100%;
      }

      h1 {
        font-size: 1.5rem;
      }

      h2 {
        /* margin: auto 0px; */
        color: ${({ theme }) => theme.colors.color_02};
      }

      span {
        padding: 5px 0px;
        text-align: center;
        border-radius: 5px;
        background-color: ${({ theme }) => theme.colors.background_03};
      }

      p {
        font-size: 1.5rem;
        font-weight: bold;
      }
    }

    > div:nth-child(1),
    > div:nth-child(2) {
      border-bottom: 1px solid lightgray;
    }

    > div:nth-child(1) {
      height: 80px;
    }

    > div:nth-child(2) {
      height: 80px;
    }

    > div:nth-child(3) {
      height: 150px;
      padding-bottom: 5px;

      p {
        margin: 10px 0px;
        font-size: 1rem;
        text-align: center;
      }
    }

    > div:nth-child(4) {
      height: 50px;
      padding: 0px;

      button {
        display: flex;
        flex-direction: row;

        gap: 10px;

        align-items: center;
        justify-content: center;

        border-color: #5bbf5f;
        background-color: #53ce5d;

        &:hover {
          background-color: #5bbf5f;
        }
      }
    }

    > div:nth-child(5) {
      height: 40px;
      padding: 0px;
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;
