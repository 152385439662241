import React from "react";
import { Slider, ToggleSwitchContainer } from "./styles";

function ToggleSwitch({ isActive, onToggle }) {
  return (
    <ToggleSwitchContainer isActive={isActive} onClick={onToggle}>
      <Slider isActive={isActive} />
    </ToggleSwitchContainer>
  );
}

export default ToggleSwitch;
