import React from "react";

import {
  ProductImagensContainer,
  ProductImagensSubContainer,
  ProductItemsContainer,
} from "./styles";

import Modal from "../../../../Components/Modal";
import Input from "../../../../Components/Input";
import Select from "../../../../Components/Select";
import Button from "../../../../Components/Button";
import ImageUpload from "../../../../Components/ImageUpload";

import {
  FIND_PRODUCT,
  IMAGE_PRODUCT,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_IMAGE,
} from "../../../../Api/Produto";

import {
  CREATE_PRODUCT_ITEM,
  DELETE_PRODUCT_ITEM,
} from "../../../../Api/ProdutoItens";

import {
  CREATE_PRODUCT_IMAGE,
  DELETE_PRODUCT_IMAGE,
} from "../../../../Api/ProdutoImagens";

import useForm from "../../../../Hook/useForm";

export const ModalUpdate = ({
  isOpen,
  setIsOpen,
  idProduct,
  setIdProduct,
  categories,
  fetchProducts,
}) => {
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const owner = useForm(true);
  const name = useForm(true);
  const price = useForm("price");
  const description = useForm(false);

  const [idCategories, setIdCategories] = React.useState(null);

  const [currentStock, setCurrentStock] = React.useState(1);
  const [minimumStock, setMinimumStock] = React.useState(1);

  const newItem = useForm(null);
  const [productItems, setProductItems] = React.useState([]);

  const [, setImage] = React.useState(null);
  const [imagePreview, setImagePreview] = React.useState("");

  const newImage = useForm(null);
  const newImagePreview = useForm(null);
  const [productImages, setProductImages] = React.useState([]);

  const onClose = () => {
    setIdProduct(null);
    setIsOpen(false);
    setError("");
    owner.setValue("");
    name.setValue("");
    price.setValue("");
    description.setValue("");
    setIdCategories(null);
    setCurrentStock("1");
    setMinimumStock("1");
    newItem.setValue("");
    setProductItems([]);
    setImage("");
    setImagePreview("");
    newImage.setValue(null);
    newImagePreview.setValue(null);
    setProductImages([]);
  };

  const handleAddItemToProduct = async () => {
    try {
      setLoading(true);

      if (newItem.validation()) {
        const body = {
          id_produto: idProduct,
          nome: newItem.value,
        };

        const token = window.localStorage.getItem("token");

        const { url, options } = CREATE_PRODUCT_ITEM(body, token);

        const response = await fetch(url, options);
        const responseJson = await response.json();

        if (responseJson.ok === "true") {
          window.alert(responseJson.mensagem);
          newImage.setValue("");
          newImagePreview.setValue("");
          findProduct(idProduct);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveItemToProduct = async (id) => {
    try {
      setLoading(true);

      const confirmed = window.confirm(
        "Tem certeza de que deseja excluir este item?"
      );

      if (!confirmed) {
        return;
      }

      const token = window.localStorage.getItem("token");

      const { url, options } = DELETE_PRODUCT_ITEM(id, token);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        window.alert(responseJson.mensagem);
        findProduct(idProduct);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddImageToProduct = async () => {
    try {
      setLoading(true);

      if (newImage.validation()) {
        const formData = new FormData();
        formData.append("imagem", newImage.value);
        formData.append("id_produto", idProduct);

        const token = window.localStorage.getItem("token");

        const { url, options } = CREATE_PRODUCT_IMAGE(formData, token);

        const response = await fetch(url, options);
        const responseJson = await response.json();

        if (responseJson.ok === "true") {
          window.alert(responseJson.mensagem);
          findProduct(idProduct);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImageToProduct = async (id) => {
    try {
      setLoading(true);

      const confirmed = window.confirm(
        "Tem certeza de que deseja excluir esta imagem?"
      );

      if (!confirmed) {
        return;
      }

      const token = window.localStorage.getItem("token");

      const { url, options } = DELETE_PRODUCT_IMAGE(id, token);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        window.alert(responseJson.mensagem);
        findProduct(idProduct);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateMainImageProduct = async (event) => {
    try {
      setError("");
      setLoading(true);

      const file = event.target.files[0];

      if (file != null) {
        const formData = new FormData();
        formData.append("imagem", file);

        const token = window.localStorage.getItem("token");

        const { url, options } = UPDATE_PRODUCT_IMAGE(
          idProduct,
          formData,
          token
        );

        const response = await fetch(url, options);
        const responseJson = await response.json();

        if (responseJson.ok === "true") {
          window.alert(responseJson.mensagem);
        } else {
          if (responseJson.erro) setError(responseJson.erro);
          if (responseJson.falha) setError(responseJson.falha);
        }
      }
    } catch (error) {
      setError(
        "Falha na atualização. Verifique suas credenciais e tente novamente."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setError("");
      setLoading(true);

      if (
        owner.validation() &&
        name.validation() &&
        price.validation() &&
        idCategories != null &&
        currentStock > 0 &&
        minimumStock > 0
      ) {
        const formData = new FormData();

        formData.append("id_produto", idProduct);
        formData.append("dono", owner.value);
        formData.append("nome", name.value);
        formData.append("preco", price.value.replace(",", "."));
        formData.append("descricao", description.value);
        formData.append("id_categoria", idCategories);
        formData.append("estoque_atual", currentStock);
        formData.append("estoque_minimo", minimumStock);

        const token = window.localStorage.getItem("token");

        const { url, options } = UPDATE_PRODUCT(formData, token);

        const response = await fetch(url, options);
        const responseJson = await response.json();

        if (responseJson.ok === "true") {
          window.alert(responseJson.mensagem);
          await fetchProducts();
          onClose();
        } else {
          if (responseJson.erro) setError(responseJson.erro);
          if (responseJson.falha) setError(responseJson.falha);
        }
      } else {
        setError("Preencha todos os campos");
      }
    } catch (err) {
      setError(
        "Falha na atualização. Verifique suas credenciais e tente novamente."
      );
    } finally {
      setLoading(false);
    }
  };

  const findProduct = React.useCallback(
    async (id) => {
      if (!id) return;

      try {
        setLoading(true);
        setError("");

        const token = window.localStorage.getItem("token");

        const { url, options } = FIND_PRODUCT(id, token);

        const response = await fetch(url, options);
        const responseJson = await response.json();

        if (responseJson.ok === "true") {
          const productData = responseJson.produto;

          owner.setValue(productData.dono);
          name.setValue(productData.nome);
          price.setValue(productData.preco.replace(".", ","));
          description.setValue(productData.descricao);
          setIdCategories(productData.id_categoria);
          setProductItems(productData.produto_itens);
          setProductImages(productData.produto_imagens);
          setImage(IMAGE_PRODUCT(productData?.imagem_produto));
          setImagePreview(IMAGE_PRODUCT(productData?.imagem_produto));
        }
      } catch (error) {
        setError(
          "Falha ao buscar. Verifique suas credenciais e tente novamente."
        );
      } finally {
        setLoading(false);
      }
    },
    [name, description, owner, price] // Dependencies of the callback
  );

  React.useEffect(() => {
    if (idProduct) findProduct(idProduct);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [idProduct]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} onSubmit={handleSubmit}>
      <h1>Atualização de Produto</h1>
      <Input
        required
        type="text"
        disabled={loading}
        title="Dono do Produto"
        {...owner}
        label="Proprietário"
      />
      <Input
        required
        type="text"
        disabled={loading}
        title="Nome do Produto"
        {...name}
        label="Nome"
      />
      <Input
        required
        type="text"
        disabled={loading}
        title="Preço do Produto"
        {...price}
        label="Preço R$"
      />
      <Input
        // required
        type="text"
        disabled={loading}
        title="Descrição do Produto"
        {...description}
        label="Descrição"
      />
      <Select
        required
        disabled={loading}
        title="Categoria do Produto"
        value={idCategories}
        onChange={(e) => setIdCategories(e.target.value)}
      >
        <option value="">Selecione uma categoria</option>
        {categories.map((category) => (
          <option key={category?.id} value={category?.id}>
            {category?.nome}
          </option>
        ))}
      </Select>
      <Button type="submit" onClick={() => handleSubmit()} disabled={loading}>
        Atualizar Produto
      </Button>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <ProductItemsContainer>
        <h2>Itens do Produto</h2>
        <Input
          type="text"
          disabled={loading}
          title="Item do Produto"
          {...newItem}
          label="Nome do Item"
        />
        <Button type="button" onClick={handleAddItemToProduct}>
          Adicionar Item
        </Button>
        <ul>
          {productItems.map((item, index) => (
            <li key={index}>
              {item.nome}
              <Button
                type="button"
                title="Apagar item do produto"
                onClick={() => handleRemoveItemToProduct(item.id)}
              >
                -
              </Button>
            </li>
          ))}
        </ul>
      </ProductItemsContainer>
      <ProductImagensContainer>
        <h2>Imagem Principal</h2>
        <ImageUpload
          label="Atualizar Imagem"
          loading={loading}
          setImage={setImage}
          imagePreview={imagePreview}
          setImagePreview={setImagePreview}
          onChange={(event) => handleUpdateMainImageProduct(event)}
        />
        <h2>Imagens Secundárias</h2>
        <ImageUpload
          label="Escolha uma Imagem"
          loading={loading}
          setImage={newImage.setValue}
          imagePreview={newImagePreview.value}
          setImagePreview={newImagePreview.setValue}
          onChange={() => {}}
        />
        <Button type="button" onClick={handleAddImageToProduct}>
          Adicionar Imagem
        </Button>
        <ProductImagensSubContainer>
          <h3>Imagens Cadastradas</h3>
          {productImages?.length > 0 ? (
            productImages.map((i, index) => (
              <>
                <img
                  key={index}
                  alt="Imagem do Produto"
                  src={IMAGE_PRODUCT(i?.nome)}
                />
                <Button
                  type="button"
                  title="Apagar item do produto"
                  onClick={() => handleRemoveImageToProduct(i?.id)}
                >
                  -
                </Button>
              </>
            ))
          ) : (
            <h4>Nenhuma imagem cadastrada.</h4>
          )}
        </ProductImagensSubContainer>
      </ProductImagensContainer>
    </Modal>
  );
};
