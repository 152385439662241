import React from "react";
import loadingGif from "../../Assets/SeuGift(carregando).gif";
import { Gif, Loader } from "./styles";

function Loading() {
  return (
    <Loader>
      <Gif src={loadingGif} alt="Loading..." />
    </Loader>
  );
}

export default Loading;
