import styled from "styled-components";

export const ToggleSwitchContainer = styled.div`
  width: 48px;
  height: 24px;
  margin: 0 auto;
  background-color: ${(props) =>
    props.isActive ? props.theme.colors.color_01 : "#ccc"};
  border-radius: 12px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
`;

export const Slider = styled.div`
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 1px;
  transition: transform 0.3s;
  transform: ${(props) =>
    props.isActive ? "translateX(25px)" : "translateX(1)"};
`;
