import React from "react";
import { HeaderContainer } from "./styles";
import Logo from "../../Assets/SeuGift.png";
import WhatsApp from "../../Assets/whatsapp.png";
import { ENDERECO_GOOGLEMAPS, WHATSAPP_SEUGIFT } from "../../Helper/link";

export const Header = () => {
  return (
    <HeaderContainer>
      <div>
        <img src={Logo} width={45} alt="SeuGift Logo" />
        <nav>
          <a
            rel="noreferrer"
            target="_parent"
            title="Voltar para a página inicial"
            href="/"
          >
            Seugift
          </a>
        </nav>
        <p>Fortalecendo Relacionamentos</p>
      </div>
      <div>
        <p>
          Site em construção, Pedidos
          <span> (19 98767-8840) </span>
          <a target="_blank" rel="noreferrer" href={WHATSAPP_SEUGIFT}>
            <img src={WhatsApp} height={20} alt="WhatsApp Icon" />
          </a>
        </p>
        <p>ou em nossa loja</p>
        <a target="_blank" rel="noreferrer" href={ENDERECO_GOOGLEMAPS}>
          Rua Almirante Tamandaré, 862 - Indaiatuba - SP
        </a>
      </div>
      <div>
        <p>
          Temos uma grande variedade de Cestas, personalizamos o conteúdo
          conforme a necessidade do cliente ! Em função disso, os preços podem
          variar bastante, consulte-nos
        </p>
      </div>
    </HeaderContainer>
  );
};
