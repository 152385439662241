import styled from "styled-components";

export const HeaderContainer = styled.header`
  width: 100%;

  padding: 0 5px;
  margin-bottom: 10px;

  background-color: ${({ theme }) => theme.colors.color_01};

  a,
  p {
    text-align: center;
    color: ${({ theme }) => theme.colors.text_02};
  }

  a {
    font-weight: bold;
  }

  > div:nth-child(1) {
    display: flex;
    flex-direction: column;

    gap: 5px;
    align-items: center;
    justify-content: space-between;

    a,
    p {
      flex: 1;
      width: 100%;
    }

    a {
      font-size: 1.5rem;
      font-style: italic;
    }

    p {
      width: fit-content;
      font-size: 1.125rem;
    }

    > img {
      position: fixed;
      top: 5px;
      left: 5px;

      border-radius: 5px;
    }

    @media (max-width: 1360px) {
      flex-direction: column;
    }
  }

  > div:nth-child(2) {
    margin: auto 0;
    padding: 5px 0;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    gap: 0 10px;

    a {
      font-size: 1rem;
      font-family: "serif";
    }

    p {
      font-size: 1rem;

      display: flex;
      flex-direction: row;

      gap: 5px;
      align-items: center;
    }

    @media (max-width: 920px) {
      flex-direction: column;
    }
  }
`;
