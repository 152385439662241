import { API_URL } from "..";

const API_URL_USER = API_URL + "usuarios";

export function AUTH_USER({ email, senha }) {
  return {
    url: API_URL_USER + "/autenticar",
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, senha }),
    },
  };
}

export function FIND_USER(token) {
  return {
    url: `${API_URL_USER}/buscar`,
    options: {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}
