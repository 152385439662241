import styled from "styled-components";

export const Loader = styled.div`
  position: fixed;
  top: 40%;
  left: 41%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Gif = styled.img`
  width: 250px;
  height: 250px;
  border-radius: 20px;
`;
