import React from "react";
import PropTypes from "prop-types";

import { Button, Container, Image, Slide, Slides } from "./styles";

import { IMAGE_PRODUCT } from "../../Api/Produto";

function Carousel({ images }) {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  if (images?.length > 1) {
    return (
      <Container>
        <Slides
          $totalSlides={images.length}
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <Slide key={index}>
              <Image src={IMAGE_PRODUCT(image?.nome)} alt={`Slide ${index}`} />
            </Slide>
          ))}
        </Slides>
        <Button direction="prev" onClick={prevSlide}>
          ❮
        </Button>
        <Button direction="next" onClick={nextSlide}>
          ❯
        </Button>
      </Container>
    );
  } else {
    return (
      <Container>
        <Slides
          totalSlides={images.length}
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <Slide key={index}>
              <Image src={IMAGE_PRODUCT(image?.nome)} alt={`Slide ${index}`} />
            </Slide>
          ))}
        </Slides>
      </Container>
    );
  }
}

export default Carousel;

Carousel.propTypes = {
  images: PropTypes.array,
};
