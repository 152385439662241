import styled from "styled-components";

export const ButtonContainer = styled.button`
  width: 100%;
  min-height: 40px;
  height: auto;

  padding: 0.3125rem;

  cursor: pointer;

  &:hover {
    transition: 0.3s;
    background-color: ${({ theme }) => theme.colors.color_02};
  }

  &:disabled {
    opacity: 0.5rem;
    cursor: not-allowed;
  }
`;
