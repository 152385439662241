import IconeCesta from "../../Assets/cesta.png";
import { API_URL } from "..";

const API_URL_PRODUCT = API_URL + "produtos";

export function CREATE_PRODUCT(formData, token) {
  return {
    url: `${API_URL_PRODUCT}/cadastrar`,
    options: {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    },
  };
}

export function FIND_PRODUCT(idProduct, token) {
  return {
    url: `${API_URL_PRODUCT}/buscar?id_produto=${idProduct}`,
    options: {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}

export function LIST_PRODUCTS(token, status, idCategory, nameProduct) {
  let query = "";

  if (status || idCategory || nameProduct) {
    query = "?";
    if (status) query += `ativo=${status}&`;
    if (idCategory) query += `id_categoria=${idCategory}&`;
    if (nameProduct) query += `nome=${nameProduct}&`;
    query = query.slice(0, -1);
  }

  return {
    url: `${API_URL_PRODUCT}/listar${query}`,
    options: {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}

export function CATALOG_PRODUCTS(token, status, idCategory, nameProduct) {
  let query = "";

  if (status || idCategory || nameProduct) {
    query = "?";
    if (status) query += `ativo=${status}&`;
    if (idCategory) query += `id_categoria=${idCategory}&`;
    if (nameProduct) query += `nome=${nameProduct}&`;
    query = query.slice(0, -1);
  }

  return {
    url: `${API_URL_PRODUCT}/catalogo${query}`,
    options: {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}

export function IMAGE_PRODUCT(image) {
  if (image) {
    return API_URL_PRODUCT + "/imagem_produto/" + image;
  } else {
    return IconeCesta;
  }
}

export function UPDATE_PRODUCT(formData, token) {
  return {
    url: `${API_URL_PRODUCT}/atualizar`,
    options: {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    },
  };
}

export function UPDATE_PRODUCT_STATUS(idProduct, token) {
  console.log(idProduct);
  return {
    url: `${API_URL_PRODUCT}/atualizar-status?id_produto=${idProduct}`,
    options: {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}

export function UPDATE_PRODUCT_IMAGE(idProduct, formData, token) {
  return {
    url: `${API_URL_PRODUCT}/atualizar-imagem?id_produto=${idProduct}`,
    options: {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    },
  };
}

export function DELETE_PRODUCT(idProduct, token) {
  return {
    url: `${API_URL_PRODUCT}/excluir?id_produto=${idProduct}`,
    options: {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}
