import { API_URL } from "..";

const API_URL_PRODUCT = API_URL + "produto-itens";

export function CREATE_PRODUCT_ITEM(body, token) {
  console.log(body);
  return {
    url: `${API_URL_PRODUCT}/cadastrar`,
    options: {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    },
  };
}

export function DELETE_PRODUCT_ITEM(idItem, token) {
  return {
    url: `${API_URL_PRODUCT}/excluir?id_item=${idItem}`,
    options: {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}
