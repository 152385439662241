import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
`;

export const Label = styled.label`
  width: 100%;
  height: 40px;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  cursor: pointer;

  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.text_02};

  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-radius: 0.5rem 0.5rem 0 0;
  border-color: ${({ theme }) => theme.colors.color_02};

  background-color: ${({ theme }) => theme.colors.color_01};

  &:hover {
    background-color: ${({ theme }) => theme.colors.color_03};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const Input = styled.input`
  display: none;
`;

export const ImagePreview = styled.div`
  width: 100%;
  height: fit-content;

  padding-top: 5px;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-radius: 0 0 0.5rem 0.5rem;
  border-color: ${({ theme }) => theme.colors.border_03};

  background-color: ${({ theme }) => theme.colors.background_02};
`;

export const PreviewImage = styled.img`
  height: 200px;
  object-fit: cover;
  border: 1px solid #ddd;
`;
