import styled from "styled-components";

export const CatalogContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  > div:nth-child(2) {
    width: 100%;
    height: calc(100% + 10px);

    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(0, 300px));

    align-items: start;
    justify-content: center;

    gap: 10px;

    overflow-y: auto;

    > div {
      flex: 1;
    }
  }
`;

export const FilterContainer = styled.div`
  width: 100%;
  height: 40px;

  padding: 0 20px;
  margin-bottom: 10px;

  display: flex;
  flex-direction: row;

  input,
  select {
    height: 100%;
    border-color: ${({ theme }) => theme.colors.color_01};
  }

  input {
    width: 75%;
    padding: 0px 5px;
    font-size: 1.125rem;
    border-width: 2px 0px 2px 2px;
    border-radius: 5px 0px 0px 5px;
  }

  select {
    width: 25%;
    padding: 0px 5px;
    font-size: 1.125rem;
    border-width: 2px;
    border-radius: 0px 5px 5px 0px;
  }
`;

export const ModalProductContainer = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;

  gap: 5px;
  align-items: center;

  > div:nth-child(2) {
    width: 100%;
    height: 250px;
    border-radius: 10px;
  }

  ul {
    width: 100%;
    text-align: center;
    list-style-type: none;
  }
`;
