import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    
    box-sizing: border-box;
  }

  body {
    -webkit-font-smoothing: antialiased;

    color: ${({ theme }) => theme.colors.text_01};
    background-color: ${({ theme }) => theme.colors.background_01};

    font-weight: 400;
    font-family: ${({ theme }) => theme.font.family};

    border-radius: 0.5rem;
    border-color: ${({ theme }) => theme.colors.border_01};
  }

  input, textarea, select {
    font-weight: 400;
    font-family: ${({ theme }) => theme.font.family};

    border-radius: 0.5rem;

    color: ${({ theme }) => theme.colors.text_01};
    background-color: ${({ theme }) => theme.colors.background_01};

    &:hover {
      background-color: ${({ theme }) => theme.colors.background_02};
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  button {
    font-weight: 400;
    font-family: ${({ theme }) => theme.font.family};

    border-radius: 0.5rem;
    border-color: ${({ theme }) => theme.colors.color_01};

    color: ${({ theme }) => theme.colors.text_02};
    background-color: ${({ theme }) => theme.colors.color_01};
    
    &:hover {
      background-color: ${({ theme }) => theme.colors.color_02};
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  button, select {
    cursor: pointer;
    font-size: 1.125rem;
  }

  img {
    display: block;
    max-width: 100%;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;

  scrollbar-width: none;
  scrollbar-color: transparent transparent;
`;

export default GlobalStyle;
