import React from "react";
import { useNavigate } from "react-router-dom";

import {
  FIND_PRODUCT,
  IMAGE_PRODUCT,
  CATALOG_PRODUCTS,
} from "../../Api/Produto";

import {
  CatalogContainer,
  FilterContainer,
  ModalProductContainer,
} from "./styles";

import Modal from "../../Components/Modal";
import CatalogItem from "./Components/CatalogItem";
import Carousel from "../../Components/Carousel";
import Loading from "../../Components/Loading";

export const Catalog = () => {
  const navigate = useNavigate();

  const [product, setProduct] = React.useState({});
  const [catalog, setCatalog] = React.useState([]);

  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const [isOpenModal, setIsOpenModal] = React.useState(false);

  const [idCategory, setIdCategory] = React.useState(0);
  const [nameProduct, setNameProduct] = React.useState("");

  const openProductDetails = async (idProduct) => {
    if (idProduct) navigate(`/product-detail/${idProduct}`);
  };

  // const openModal = async (idProduto) => {
  //   try {
  //     const { url, options } = FIND_PRODUCT(idProduto, "");

  //     const response = await fetch(url, options);
  //     const responseJson = await response.json();

  //     if (responseJson.ok === "true") {
  //       setIsOpenModal(true);
  //       setProduct(responseJson.produto);
  //     }
  //   } catch (err) {
  //     setError(err.message);
  //   }
  // };

  const closeModal = () => {
    setProduct({});
    setIsOpenModal(false);
  };

  const fetchProducts = React.useCallback(async () => {
    try {
      setError("");
      setLoading(true);

      const { url, options } = CATALOG_PRODUCTS(
        "",
        "1",
        idCategory,
        nameProduct
      );

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        setCatalog(responseJson.produtos);
      } else {
        if (responseJson.erro) setError(responseJson.erro);
        if (responseJson.falha) setError(responseJson.falha);
      }
    } catch (err) {
      setError("Falha no servidor. Tente novamente mais tarde.");
    } finally {
      setLoading(false);
    }
  }, [idCategory, nameProduct]);

  React.useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const renderCatalogContent = () => {
    if (loading === true) {
      return <Loading />;
    }

    if (error) {
      return <strong>{error}</strong>;
    }

    if (catalog.length > 0) {
      return catalog.map((item) => (
        <CatalogItem
          key={item?.id}
          onClick={() => openProductDetails(item.id)}
          productName={item?.nome}
          numberItens={item?.num_itens}
          categoryName={item?.nome_categoria}
          productPrice={item?.preco}
          productImage={IMAGE_PRODUCT(item?.imagem_produto)}
        />
      ));
    }
  };

  const renderModalContent = () => {
    if (!product) {
      return (
        <Modal isOpen={isOpenModal} onClose={closeModal}>
          <h1>Carregando...</h1>
        </Modal>
      );
    }

    if (product) {
      return (
        <Modal isOpen={isOpenModal} onClose={closeModal}>
          <ModalProductContainer>
            <h1>{product.nome}</h1>
            <Carousel
              images={[
                { nome: product?.imagem_produto || "" },
                ...(product?.produto_imagens || []),
              ]}
            />
            <h2>R$ {product.preco}</h2>
            <p>{product.descricao}</p>
            {product?.produto_itens?.length ? (
              <h3>Item(s) do Produto</h3>
            ) : null}
            <ul>
              {product?.produto_itens?.length > 0 &&
                product.produto_itens.map((item) => (
                  <li key={item.id}>{item.nome}</li>
                ))}
            </ul>
          </ModalProductContainer>
        </Modal>
      );
    }
  };

  return (
    <CatalogContainer>
      <FilterContainer>
        <input
          type="text"
          value={nameProduct}
          onChange={(e) => setNameProduct(e.target.value)}
          placeholder="Procure pelo Nome do Produto"
        />
        <select
          value={idCategory}
          onChange={(e) => setIdCategory(Number(e.target.value))}
        >
          <option value={0}>Selecione uma Categoria</option>
          <option value={1}>Cesta</option>
          <option value={2}>Artesanal</option>
          <option value={3}>Escritório</option>
        </select>
      </FilterContainer>
      <div>{renderCatalogContent()}</div>
      {renderModalContent()}
    </CatalogContainer>
  );
};
