import { CatalogItemContainer } from "./styles";

import Button from "../../../../Components/Button";

const CatalogItem = ({
  onClick,
  productName,
  numberItens,
  categoryName,
  productPrice,
  productImage,
}) => {
  return (
    <CatalogItemContainer onClick={onClick}>
      <div>
        <img alt="Imagem do Produto" src={productImage} />
      </div>
      <div>
        <h1>{productName}</h1>
        {numberItens ? <h3>Contém {numberItens} item(s)</h3> : null}
        {productPrice === "0,00" ? (
          <span>Consulte</span>
        ) : (
          <span>R$ {productPrice}</span>
        )}

        <Button title="Ver mais detalhes do produto">Detalhes</Button>
      </div>
    </CatalogItemContainer>
  );
};

export default CatalogItem;
