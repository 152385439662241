export const theme = {
  colors: {
    text_01: "#000000",
    text_02: "#ffffff",

    color_01: "#ec5b6e",
    color_02: "#eb294a",
    color_03: "#a61d37",

    border_01: "#0a0a0a",
    border_02: "#171717",
    border_03: "#262626",

    background_01: "#fafafa",
    background_02: "#f5f5f5",
    background_03: "#e5e5e5",
  },
  font: {
    family: '"Merriweather", serif',
  },
};
