import React from "react";

import { ProductItemsContainer } from "./styles";

import Modal from "../../../../Components/Modal";
import Input from "../../../../Components/Input";
import Select from "../../../../Components/Select";
import Button from "../../../../Components/Button";

import { CREATE_PRODUCT } from "../../../../Api/Produto";

import useForm from "../../../../Hook/useForm";
import ImageUpload from "../../../../Components/ImageUpload";

export const ModalCreate = ({
  isOpen,
  setIsOpen,
  categories,
  fetchProducts,
}) => {
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const owner = useForm(true);
  const name = useForm(true);
  const price = useForm("price");
  const description = useForm(false);

  const [idCategories, setIdCategories] = React.useState(null);

  const [currentStock, setCurrentStock] = React.useState(1);
  const [minimumStock, setMinimumStock] = React.useState(1);

  const newItem = useForm(false);
  const [productItems, setProductItems] = React.useState([]);

  const [image, setImage] = React.useState(null);
  const [imagePreview, setImagePreview] = React.useState("");

  const onClose = () => {
    setIsOpen(false);
    setError("");
    owner.setValue("");
    name.setValue("");
    price.setValue("");
    description.setValue("");
    setIdCategories(null);
    setCurrentStock("1");
    setMinimumStock("1");
    newItem.setValue("");
    setProductItems([]);
    setImage("");
    setImagePreview("");
  };

  const addItemToProduct = () => {
    if (newItem.validation()) {
      setProductItems([...productItems, { nome: newItem.value }]);
      newItem.setValue("");
    }
  };

  const removeItemToProduct = (index) => {
    setProductItems(productItems.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    try {
      setError("");
      setLoading(true);

      if (
        owner.validation() &&
        name.validation() &&
        price.validation() &&
        // description.validation() &&
        idCategories != null &&
        currentStock > 0 &&
        minimumStock > 0 &&
        image != null
      ) {
        const formData = new FormData();

        formData.append("dono", owner.value);
        formData.append("nome", name.value);
        formData.append("preco", price.value.replace(",", "."));
        formData.append("descricao", description.value);
        formData.append("id_categoria", idCategories);
        formData.append("estoque_atual", currentStock);
        formData.append("estoque_minimo", minimumStock);
        formData.append("imagem", image);
        formData.append("produto_itens", JSON.stringify(productItems));

        const token = window.localStorage.getItem("token");

        const { url, options } = CREATE_PRODUCT(formData, token);

        const response = await fetch(url, options);
        const responseJson = await response.json();

        if (responseJson.ok === "true") {
          window.alert(responseJson.mensagem);
          await fetchProducts();
          onClose();
        } else {
          if (responseJson.erro) setError(responseJson.erro);
          if (responseJson.falha) setError(responseJson.falha);
        }
      } else {
        setError("Preencha todos os campos");
      }
    } catch (err) {
      setError(
        "Falha no cadastro. Verifique suas credenciais e tente novamente."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} onSubmit={handleSubmit}>
      <h1>Cadastro de Produto</h1>
      <Input
        required
        type="text"
        disabled={loading}
        title="Dono do Produto"
        {...owner}
        label="Proprietário"
      />
      <Input
        required
        type="text"
        disabled={loading}
        title="Nome do Produto"
        {...name}
        label="Nome"
      />
      <Input
        required
        type="text"
        disabled={loading}
        title="Preço do Produto"
        {...price}
        label="Preço"
      />
      <Input
        // required
        type="text"
        disabled={loading}
        title="Descrição do Produto"
        {...description}
        label="Descrição"
      />
      <Select
        required
        disabled={loading}
        title="Categoria do Produto"
        value={idCategories}
        onChange={(e) => setIdCategories(e.target.value)}
      >
        <option value="">Selecione uma categoria</option>
        {categories.map((category) => (
          <option key={category?.id} value={category?.id}>
            {category?.nome}
          </option>
        ))}
      </Select>
      <ProductItemsContainer>
        <h2>Itens do Produto</h2>
        <Input
          type="text"
          disabled={loading}
          title="Item do Produto"
          {...newItem}
          label="Nome do Item"
        />
        <Button type="button" onClick={addItemToProduct}>
          Adicionar Item
        </Button>
        <ul>
          {productItems.map((item, index) => (
            <li key={index}>
              {item.nome}
              <Button
                type="button"
                title="Apagar item do produto"
                onClick={() => removeItemToProduct(index)}
              >
                -
              </Button>
            </li>
          ))}
        </ul>
      </ProductItemsContainer>
      <ImageUpload
        label="Imagem Principal"
        loading={loading}
        setImage={setImage}
        imagePreview={imagePreview}
        setImagePreview={setImagePreview}
        onChange={() => {}}
      />
      {error && <p style={{ color: "red" }}>{error}</p>}
      <Button type="submit" onClick={() => handleSubmit()} disabled={loading}>
        Salvar
      </Button>
    </Modal>
  );
};
