import React from "react";

import { ProductListContainer } from "./styles";

import Button from "../../Components/Button";
import Loading from "../../Components/Loading";
import ToggleSwitch from "../../Components/ToogleSwitch";

import { ModalCreate } from "./Components/ModalCreate";
import { ModalUpdate } from "./Components/ModalUpdate";

import imgUpdate from "../../Assets/update.png";
import imgDelete from "../../Assets/delete.png";

import { LIST_CATEGORIES } from "../../Api/Categoria";
import {
  DELETE_PRODUCT,
  LIST_PRODUCTS,
  UPDATE_PRODUCT_STATUS,
} from "../../Api/Produto";

export const ProductList = () => {
  const [products, setProducts] = React.useState([]);
  const [categories, setCategories] = React.useState([]);

  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const [isOpenModalCreate, setIsOpenModalCreate] = React.useState(false);

  const [idProduct, setIdProduct] = React.useState(null);
  const [isOpenModalUpdate, setIsOpenModalUpdate] = React.useState(false);

  const openModalUpdate = (id) => {
    if (!id) return;
    setIdProduct(id);
    setIsOpenModalUpdate(true);
  };

  const handleUpdateStatus = async (id) => {
    if (!id) return;

    const token = window.localStorage.getItem("token");

    const { url, options } = UPDATE_PRODUCT_STATUS(id, token);

    const response = await fetch(url, options);
    const responseJson = await response.json();

    if (responseJson.ok === "true") {
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === id
            ? { ...product, ativo: responseJson.statusAtual }
            : product
        )
      );
    } else {
      if (responseJson.erro) window.alert(responseJson.erro);
      if (responseJson.falha) window.alert(responseJson.falha);
    }
  };

  const handleDeleteProduct = async (id) => {
    if (!id) return;

    const confirmed = window.confirm(
      "Tem certeza de que deseja excluir este produto?"
    );

    if (!confirmed) {
      return;
    }

    const token = window.localStorage.getItem("token");

    const { url, options } = DELETE_PRODUCT(id, token);

    const response = await fetch(url, options);
    const responseJson = await response.json();

    if (responseJson.ok === "true") {
      await fetchProducts();
    } else {
      if (responseJson.erro) window.alert(responseJson.erro);
      if (responseJson.falha) window.alert(responseJson.falha);
    }
  };

  const fetchProducts = React.useCallback(async () => {
    try {
      setLoading(true);
      setError("");

      const { url, options } = LIST_PRODUCTS("");

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        setProducts(responseJson.produtos);
      } else {
        setProducts([]);
        if (responseJson.erro) setError(responseJson.erro);
        if (responseJson.falha) setError(responseJson.falha);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchCategories = React.useCallback(async () => {
    try {
      setLoading(true);

      const token = localStorage.getItem("token");
      const { url, options } = LIST_CATEGORIES(token);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        setCategories(responseJson.categorias);
      } else {
        if (responseJson.erro) setError(responseJson.erro);
        if (responseJson.falha) setError(responseJson.falha);
      }
    } catch (err) {
      setError(
        "Falha na listagem de categorias. Verifique suas credenciais e tente novamente."
      );
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchProducts();
    fetchCategories();
  }, [fetchProducts, fetchCategories]);

  return (
    <ProductListContainer>
      <div>
        <h1>Produtos</h1>
        <Button
          type="button"
          title="Novo produto"
          onClick={() => setIsOpenModalCreate(true)}
        >
          Adicionar
        </Button>
      </div>
      <table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Preço</th>
            <th>Categoria</th>
            <th>Ativo</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <Loading />;
            </tr>
          ) : (
            <>
              {products ? (
                products.map((product) => (
                  <tr key={product?.id}>
                    <td>{product?.nome}</td>
                    <td>R$ {product?.preco}</td>
                    <td>{product?.nome_categoria}</td>
                    <td>
                      <ToggleSwitch
                        isActive={product?.ativo}
                        onToggle={() => {
                          handleUpdateStatus(product?.id);
                        }}
                      />
                    </td>
                    <td>
                      <Button
                        type="button"
                        title="Atualizar produto"
                        onClick={() => openModalUpdate(product?.id)}
                      >
                        <img
                          width="26"
                          height="26"
                          src={imgUpdate}
                          alt="Atualizar Produto"
                        />
                      </Button>
                      <Button
                        type="button"
                        title="Excluir produto"
                        onClick={() => {
                          handleDeleteProduct(product?.id);
                        }}
                      >
                        <img
                          width="26"
                          height="26"
                          src={imgDelete}
                          alt="Apagar Produto"
                        />
                      </Button>
                    </td>
                  </tr>
                ))
              ) : error ? (
                <tr>
                  <td colSpan={5}>{error}</td>
                </tr>
              ) : null}
            </>
          )}
        </tbody>
      </table>
      <ModalCreate
        isOpen={isOpenModalCreate}
        setIsOpen={setIsOpenModalCreate}
        categories={categories}
        fetchProducts={fetchCategories}
      />
      <ModalUpdate
        isOpen={isOpenModalUpdate}
        setIsOpen={setIsOpenModalUpdate}
        idProduct={idProduct}
        setIdProduct={setIdProduct}
        categories={categories}
        fetchProducts={fetchCategories}
      />
    </ProductListContainer>
  );
};
