import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ProductDetailContainer, ProductDetailSubContainer } from "./styles";

import Button from "../../Components/Button";
import ProductImageGallery from "./Components/ProductImageGallery";

import WhatsApp from "../../Assets/whatsapp.png";

import {
  CATALOG_PRODUCTS,
  FIND_PRODUCT,
  IMAGE_PRODUCT,
} from "../../Api/Produto";
import CatalogItem from "../Catalog/Components/CatalogItem";
import { NUMERO_WHATSAPP } from "../../Helper/link";

export const ProductDetail = () => {
  const navigate = useNavigate();
  const { id: idProduct } = useParams();

  const [_error, setError] = React.useState(null);
  const [_loading, setLoading] = React.useState(true);
  const [product, setProduct] = React.useState(null);

  const [idCategory, setIdCategory] = React.useState(null);
  const [products, setProducts] = React.useState([]);

  const findProduct = React.useCallback(async () => {
    try {
      setLoading(true);
      setError("");

      const { url, options } = FIND_PRODUCT(idProduct, "");

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson?.ok === "true") {
        setProduct(responseJson?.produto);
        setIdCategory(responseJson?.product?.id_categoria);
      } else {
        setProduct([]);
        if (responseJson?.erro) setError(responseJson?.erro);
        if (responseJson?.falha) setError(responseJson?.falha);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [idProduct]);

  const fetchProducts = React.useCallback(async () => {
    try {
      setError("");
      setLoading(true);

      const { url, options } = CATALOG_PRODUCTS("", "1", idCategory);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        setProducts(responseJson.produtos);
      } else {
        if (responseJson.erro) setError(responseJson.erro);
        if (responseJson.falha) setError(responseJson.falha);
      }
    } catch (err) {
      setError("Falha no servidor. Tente novamente mais tarde.");
    } finally {
      setLoading(false);
    }
  }, [idCategory]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (idProduct) {
        await findProduct();
        await fetchProducts();
      }
    };

    fetchData();
  }, [idProduct, findProduct, fetchProducts]);

  const whatsappRequest = () => {
    const message = `Olá! Gostaria de fazer o pedido uma ${product?.nome}.`;
    const encodedMessage = encodeURIComponent(message);
    const linkWhatsApp = `https://wa.me/${NUMERO_WHATSAPP}?text=${encodedMessage}`;
    window.open(linkWhatsApp, "_blank");
  };

  const renderCatalogContent = () => {
    if (products.length > 0) {
      return products.map((item) => (
        <CatalogItem
          key={item?.id}
          onClick={() => item.id && navigate(`/product-detail/${item.id}`)}
          productName={item?.nome}
          numberItens={item?.num_itens}
          categoryName={item?.nome_categoria}
          productPrice={item?.preco}
          productImage={IMAGE_PRODUCT(item?.imagem_produto)}
        />
      ));
    }
  };

  return (
    <ProductDetailContainer>
      <ProductDetailSubContainer>
        <ProductImageGallery
          images={[
            { nome: product?.imagem_produto || "" },
            ...(product?.produto_imagens || []),
          ]}
        />
        <div>
          <div>
            <h1>{product?.nome}</h1>
            <span>{product?.nome_categoria}</span>
          </div>
          <div>
            <p>Preço: </p>
            <h2>
              {product?.preco | (product?.preco !== "0,00")
                ? `R$ ${product?.preco}`
                : `Consulte-nos`}
            </h2>
          </div>
          <div>
            <h3>Itens do Produto</h3>
            <ul>
              {product?.produto_itens?.length > 0 ? (
                product?.produto_itens.map((item) => (
                  <li key={item.id}>{item.nome}</li>
                ))
              ) : (
                <p>Nenhum item registrado</p>
              )}
            </ul>
          </div>
          <div>
            <Button type="button" onClick={whatsappRequest}>
              Pedido via WhatsApp
              <img src={WhatsApp} height={25} />
            </Button>
          </div>
          <div>
            <Button type="button" onClick={() => navigate(-1)}>
              Voltar
            </Button>
          </div>
        </div>
      </ProductDetailSubContainer>
      <div>
        <h2>Descrição do Produto</h2>
        <p>{product?.descricao}</p>
      </div>
      <div>
        <h2>Outros Produtos</h2>
        <div>{renderCatalogContent()}</div>
      </div>
    </ProductDetailContainer>
  );
};
