import React from "react";
import PropTypes from "prop-types";

import { SelectContainer } from "./styles";

function Select({ children, ...props }) {
  return <SelectContainer {...props}>{children}</SelectContainer>;
}

Select.propTypes = {
  children: PropTypes.node,
};

export default Select;
