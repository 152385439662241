import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.background_03};
`;

export const Slides = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: ${(props) => props.$totalSlides * 100}%;
`;

export const Slide = styled.div`
  min-width: 100%;
  box-sizing: border-box;
`;

export const Image = styled.img`
  height: 250px;
  display: block;
  margin: 0px auto;
`;

export const Button = styled.button`
  position: absolute;
  top: 50%;
  ${(props) => (props.direction === "prev" ? "left: 10px;" : "right: 10px;")}
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  transform: translateY(-50%);
`;
