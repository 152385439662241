import React from "react";
import PropTypes from "prop-types";
import { FIND_USER } from "../../Api/Usuario";

const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [login, setLogin] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const userLogout = React.useCallback(() => {
    setData(null);
    setError(null);
    setLogin(false);
    window.localStorage.removeItem("token");
    window.location.replace("/auth");
  }, [setData, setError, setLogin]);

  const featchUserToken = React.useCallback(async () => {
    const token = window.localStorage.getItem("token");
    if (!token) return;

    try {
      setLoading(true);
      setLogin(false);

      const { url, options } = FIND_USER(token);
      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        window.localStorage.setItem("token", responseJson.token);
        setData(responseJson.usuario);
        setLogin(true);
      } else {
        userLogout();
        if (responseJson.erro) setError(responseJson.erro);
        if (responseJson.falha) setError(responseJson.falha);
      }
    } catch (error) {
      userLogout();
      setError("Falha no busca do Usuário.");
    } finally {
      setLoading(false);
    }
  }, [userLogout]);

  return (
    <UserContext.Provider
      value={{
        data,
        setData,
        error,
        setError,
        login,
        setLogin,
        loading,
        setLoading,
        userLogout,
        featchUserToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserContext };
