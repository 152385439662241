import styled from "styled-components";

export const GalleryContainer = styled.div`
  display: flex;
  gap: 5px;
`;

export const ThumbnailContainer = styled.div`
  width: 25%;
  height: 100%;

  padding: 10px;

  overflow-y: auto;
  border-right: 2px solid #ddd;
`;

export const ThumbnailImage = styled.img`
  width: 100%;
  cursor: pointer;

  margin-bottom: 10px;

  border-radius: 4px;
  border: 2px solid ${(props) => (props.selected ? "blue" : "transparent")};
`;

export const MainImageContainer = styled.div`
  width: 75%;
  height: 100%;

  position: relative;

  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.background_03};

  &::after {
    display: ${(props) => props.display || "none"};
    content: "";
    width: 100%;
    height: 100%;
    background-color: black;
    background-image: url(${(props) => props.url});
    background-size: 200%;
    background-position: ${(props) => `${props.zoomX}% ${props.zoomY}%`};
    position: absolute;
    left: 0;
    top: 0;
  }
`;

export const MainImage = styled.img`
  width: fit-content;
  height: 100%;
  margin: auto;
  object-fit: contain;
  object-position: 0 0;
`;
