import styled from "styled-components";

export const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: space-between;

  overflow: hidden;
  box-sizing: border-box;

  > div {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    height: 100%;
    overflow-y: auto;
  }
`;
